import React from "react";

const FamilyCare = () => (
    <div id="content-wrap">
        <div id="content-centering">
            <div className="element-text">
                <h1>FamilyCare</h1>
                We see many of children in our office because their parents want them to be healthy. Chiropractic can help in a wide range of health
                problems such as migraines, bed-wetting, carpal tunnel, neck, shoulder and arm pain, whiplash from car accidents, backaches, ear infections,
                asthma, allergies, failed back surgery syndrome, numbness in the limbs, athletic and work injuries, just to name a few.
		      </div>
        </div>
    </div>
);

export default FamilyCare;