import React from "react";
import sprite from './images/sprite.svg';

const Footer = () => (
    <>
        <div className="contact-bar">
            <div className="grid-720 grid-pad centering">
                <div className="col-4 align-c">
                    <div>
                        <b>Office Hours</b><br />
                        M, W, F: By Appointment
                    </div>
                </div>
                <div className="col-4 align-c">
                    <div>
                        <b>Address</b><br />
                        2616 E. Markland Ave.<br />
                        Kokomo, IN 46901-6663
                    </div>
                </div>
                <div className="col-4 align-c">
                    <div>
                        <b>Phone</b><br />
                        765.459.8551<br />
                        <b>Social Media</b><br />
                        <div className="social-media">
                            <a id="soc-facebook"
                                href="https://www.facebook.com/DillmanChiropractic/">
                                <svg className="icon">
                                    <use href={sprite + "#icon-facebook"}></use>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            COPYRIGHT © 2021 DILLMAN CHIROPRACTIC | <a href="https://www.scaredrabbit.com">KOKOMO WEB DEV BY SCARED RABBIT</a>
        </footer>
    </>
);

export default Footer;