import React from "react";
import spine from './images/spine.gif';

const VertebralSubluxation = () => (
    <div id="content-wrap">
        <div id="content-centering">
            <div className="element-text">
                <h1>Vertebral Subluxation and your Nervous System</h1>
                <img src={spine} alt="Spine" className="float-right" />
			    As a Principled Chiropractor, we look to the spine for misaligned vertebrae (spinal bones). If these bones are putting pressure
                on your brain stem and/or spinal cord, they are cutting off the life force or nerve impulses that control every function in your
                body, including your immune system and healing processes. We call this a Vertebral Subluxation.<br /><br />

			    Ninty percent (90%) of these subluxations do not cause any pain or symptoms. Often, the first sign your body may give you that subluxation is present is death.<br /><br />

			    When I find spinal bones out of place, I gently put them back in place so that they are no longer pressing on the nerves, brain stem, and/or
                spinal cord. Once the subluxation is corrected, the life force or nerve impulses can properly get from your brain into your body and all the
                power and knowledge that created your body and keeps you living can get through to fix any problems, conditions, complaints, sickness, disease,
                aches or pains that you have whether you can feel a symptom or not. When the life force or nerve impulses is working 100% of the time, your body
                is at it's highest potential for healing. You will probably experience a level of health that you may not have felt in many years.<br /><br />

			    To recreate 100% health, you must reduce subluxation long enough for your body to recreate itself from the inside out. So allow enough time for healing to happen.
		    </div>
        </div>
    </div>
);

export default VertebralSubluxation;