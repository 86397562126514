import React from "react";

const Contact = () => (
    <div id="content-wrap">
        <div id="content-centering">
            <div className="element-text">
                <h1>Contact Us</h1>

                <div className="rwd-embed embed-float-right" style={{ maxWidth: "100%", width: "400px" }}>
                    <div className="rwd-aspect rwd-embed-16-9"></div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12139.690654336207!2d-86.0955807!3d40.4769759!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa2460d995b1a7d38!2sDillman+Chiropractic!5e0!3m2!1sen!2sus!4v1517859951002"
                        width="600"
                        height="450" frameBorder="0" style={{ border: "0" }} allowFullScreen>
                    </iframe>
                    <div className="rwd-embed-overlay"></div>
                </div>

                <b>Dillman Chiropractic</b><br />
                Dr. Matt Dillman<br />
                2616 E. Markland Ave.<br />
                Kokomo, IN 46901-6663<br /><br />

                <b>Phone:</b><br />
			    765.459.8551<br /><br />

                <b>Email:</b><br />
                <a href="mailto:DILLMANCHIRO@aol.com">DILLMANCHIRO@aol.com</a><br />
                <a href="https://www.facebook.com/DillmanChiropractic/">Facebook</a><br /><br />

                <b>Office Hours:</b><br />
			    By Appointment
            </div>
        </div>
    </div>
);

export default Contact;