import React from "react";

const DrMattDillman = () => (
    <div id="content-wrap">
        <div id="content-centering">
            <div className="element-text">
                <h1>Dr. Matt Dillman</h1>
                Dr. Dillman is a 1986 honors graduate of Cleveland Chiropractic College. He has studied chiropractic
                philosophy and technique extensively. He also speaks on these subjects internationally as well as to local groups.
                He has completed several chiropractic missions to Panama, where he and others adjusted thousands of people
                each day. Patients from tiny babies to professional athletes have been entrusted to his care! Dr. Dillman's staff
                are friendly and warm and they try their best to make you feel at home.
			    <br /><br />
			    Dillman Chiropractic offers a wonderful service at an exceptional fee!
		      </div>
        </div>
    </div>
);

export default DrMattDillman;