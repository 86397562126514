import React from "react";
import { Route, Switch, NavLink, BrowserRouter as Router } from "react-router-dom";
import logo from './images/logo.png';
import sprite from './images/sprite.svg';

const Header = (_setContentPage) => {
    const activeStyle = { color: "#F15B2A" };

    return (
        <>
            <header className="App-header">
                <a href="#" id="logo">
                    <img src={logo} alt="Dillman Chiropractic" />
                </a>
                <svg id="swoop" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 1000 200" enableBackground="new 0 0 1000 200" xmlSpace="preserve">
                    <path fill="#FFFFFF" d="M0,0c0,0,438.208,182.541,712.875,143.875S1000,20.25,1000,20.25V200H0V0z" />
                </svg>
                <a href="#" id="hamburger" style={{ display: "none" }} >
                    <svg className="icon"><use xlinkHref={sprite + "#icon-hamburger"} /></svg>
                </a>
                <nav className="nav-desktop default-white-text" id="main-nav">
                    <ul>
                        <li>
                            <NavLink className="menu-spacing" to="/" activeStyle={activeStyle} exact>Home</NavLink>
                        </li>
                        <li>
                            <NavLink className="menu-spacing" to="/dr-matt-dillman" activeStyle={activeStyle}>Dr. Matt Dillman</NavLink>
                        </li>
                        <li>
                            <NavLink className="menu-spacing" to="/family-care" activeStyle={activeStyle}>Family Care</NavLink>
                        </li>
                        <li>
                            <NavLink className="menu-spacing" to="/vertebral-subluxation" activeStyle={activeStyle}>Vertebral Subluxation</NavLink>
                        </li>
                        <li>
                            <NavLink className="menu-spacing" to="/contact" activeStyle={activeStyle}>Contact</NavLink>
                        </li>
                    </ul>
                </nav>
            </header>
        </>
    )
};

export default Header;