import './orig.css';
import React, { useEffect, useState } from "react";
import { Route, Switch, NavLink, BrowserRouter as Router } from "react-router-dom";
import Header from './Header';
import Content from './Content';
import DrMattDillman from './DrMattDillman';
import FamilyCare from './FamilyCare';
import VertebralSubluxation from './VertebralSubluxation';
import Contact from "./Contact";
import Footer from './Footer';

function App() {
  return (
    <div className="site-wrap">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <Content />
          </Route>
          <Route path="/dr-matt-dillman">
            <DrMattDillman />
          </Route>
          <Route path="/family-care">
            <FamilyCare />
          </Route>
          <Route path="/vertebral-subluxation">
            <VertebralSubluxation />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
