import React from "react";

const Content = () => (
    <div id="content-wrap">
        <div id="content-centering">
            <div className="element-text">
                <h1>Welcome to Dillman Chiropractic</h1>
			      You are going to experience Principled Chiropractic at Dillman Chiropractic. We are completely
                  different from conventional medicine, physical therapy or any other health care field. Our focus
                  is not to just treat pain or disease, our goal is to find the CAUSE of your problem. Our office is
                  both friendly and warm and we try our best to make you feel at home. At Dillman Chiropractic, you
                  will receive outstanding care at a very reasonable fee.
			      <br /><br />
			      Please give us a call today!
		      </div>
        </div>
    </div>
);

export default Content;